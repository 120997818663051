$color--green01: #00857C;
$color--green02: #E7F2F1;
$color--grey00: #EEF1F4;
$color--grey01: #D3E0ED;
$color--grey02: #A6B0B9;
$color--grey03: #4A4E52;
$color--calidus-blue: #1C2444;
$color--blue01: #003366;
$color--blue02: #DBDDE2;

/**
 * Call to Action (CTA)
 **/

$color--cta: white;
$color--cta-contrast: $color--green01;

$color--cta-alt: $color--green01;
$color--cta-alt-contrast: $color--green02;

%color-cta--block {
  color: $color--cta-alt;
  background-color: $color--cta-alt-contrast;
}

%color-cta--active-block {
  color: $color--cta;
  background-color: $color--cta-contrast;
}

/**
 * Content
 **/
$color--content: $color--grey03;
$color--content-contrast: white;

$color--content-alt: $color--grey03;
$color--content-alt-contrast: $color--grey00;

$color--reduced: $color--grey02;

$color--accent: $color--blue01;
$color--accent-alt: $color--green01;

$color--link: $color--green01;
$color--link-active: $color--calidus-blue;

%color__link {
  color: $color--link;
  transition: color .3s ease;
  &:hover,
  &:focus {
    color: $color--link-active;
  }
}

/**
 * Like and Interest (like)
 **/

$color--like: white;
$color--like-contrast: $color--blue01;

$color--like-alt: $color--blue01;
$color--like-alt-contrast: $color--blue02;

 %color-like--block {
  color: $color--like-alt;
  background-color: $color--like-alt-contrast;
}

%color-like--active-block {
  color: $color--like;
  background-color: $color--like-contrast;
}

