/**
 * This `%placeholder` is intended to be applied to a ::before or ::after element
 * of an empty element. It's width and height should be quare to landscape, on portrait it
 * will fail.
 */
%placeholder {
  content: '42';
  display: inline-block;
  color: transparent;
  background: rgba(0,0,0,0.2);
  background: linear-gradient(-45deg, $color--green02 48%, rgba($color--green02,0.5) 50%, $color--green02 52%);
  border-radius: 0.25em;
  
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderAnimate;
  background-size: 200%;
}

@keyframes placeholderAnimate {
    0%{ background-position-x: 100%; }
    100%{ background-position-x: -100%; }
}