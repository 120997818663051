%posts-list__info-block {
  flex-grow: 0;
  @extend %block-with-svg-icon;
  * + & {
    margin-left: 1.25rem;
  }
}

.posts-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;

  &__post {
    @extend %reset;
    @extend %layout__content-box;
    color: $color--content-alt;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0.25rem 0.5rem rgba(0,0,0 ,0.15);
    width: 100%;
    align-items: flex-start;
    align-content: space-between;

    @include respond-to('>hands') {
      width: 48%;
      margin: 0;
      margin-top: 0;
    }

    @include respond-to('=lap') {
      &:nth-child(1n + 3) {
        margin-top: 2%;
      }
    }

    @include respond-to('>lap') {
      width: 32%;
      &:nth-child(1n + 4) {
        margin-top: 2%;
      }
    }

  }
  &__media {
    border-radius: 1rem;
    display: block;
    height: 0;
    width: 100%;
    padding-top: 48%;
    background-size: cover;
    background-position: 50% 50%;
    * + & {
      margin-top: 1em;
    }
  }
  &__title {
    @extend %typography__header2;
    color: black;
    font-weight: 600;
    * + & {
      margin-top: 1em;
    }
  }
  &__excerpt {
    @extend %typography__content;
    width: 100%;
    margin: 1em 0;
  }
  &__timestamp {
    flex-grow: 1;
    padding: 1rem;
    margin: -1rem;
    @extend %posts-list__info-block;
  }
  &__opened-count {
    @extend %posts-list__info-block;
  }
  &__likes-count {
    @extend %posts-list__info-block;
  }
  &__comment-count {
    @extend %posts-list__info-block;
    &__placeholder {
      @extend %placeholder;
      width: 1.5em;
      height: 1em;
    }
  }
}