%typography__header1 {
  font-size: 1.83rem;
  line-height: 1.37;
  color: $color--accent;
  font-weight: bold;
}

%typography__header2 {
  font-size: 1.5rem; //18pt
  line-height: 1.22; //22pt
  font-weight: bold;
}

%typography__header3 {
  font-size: inherit;
  font-weight: bold;
}

%typography__content {
  font-size: 1.25rem;
  line-height: 1.4;
}

%typography__property {
  font-size: 1.08rem;
  line-height: 1.4;
}

h1, h2, h3, h4, h5, h6 {
  @extend %reset;
}
h3, h4, h5, h6 {
  @extend %typography__header3;
}

p {
  margin: 0;
  font-size: inherit;
  color: $color--grey03;
  * + & {
    margin-top: $whitespace--sibling;
  }
}