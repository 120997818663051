%pdf-link {
  border: solid 1px $color--grey01;
  padding: 0.5 * $whitespace--inner-block $whitespace--inner-block;
  margin-bottom: 0.5 * $whitespace--inner-block;
  border-radius: 8px;
  display: flex;
  align-items: center;

  @include respond-to('>hands') {
    min-height: 60px;
  }
  @include respond-to('>desk') {
    min-height: 42px;
  }
}

.chart-view {
  padding: $whitespace--inner-block 2rem 6 * $whitespace--inner-block;
  &__title {
    @extend %typography__header1;
    & + * {
      margin-top: $whitespace--relation;
    }
  }
  &__content {
    @extend %typography__content;
    @extend %content__image--container;
  }
}

.chart-view__content {
  @extend %wp-content;
  @include respond-to('>hands') {
    display: grid;
    grid-template-columns: repeat(auto-fill, 32%);
    justify-content: space-between;
    grid-gap: 10px;
  }

  * + p {
    margin-top: 0;
  }

  a {
    color: #000;

    &[href$=".pdf"] {
      @extend %pdf-link;

      &::before {
        @extend %link-icon;
        color: $color--green01;
        content: "\f1c1";
        margin-right: 1rem;
        font-size: 1.5rem;
      }

    }

  }

}