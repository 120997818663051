.main-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__logo {
        height: 40px;
        width: auto;
        @include respond-to('>hands') {
            display: none;
        }
    }
}