.navigation-view {
    width: 100%;
    border: 1px solid rgba(0,0,0 ,0.15);
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background: #ffffff;
    @include respond-to('>=lap') {
        top: 0;
        bottom: auto;
        justify-content: space-between;
    }
    &__logo {
        display: none;
        @include respond-to('>=lap') {
            display: flex;
            max-height: 4rem;
            width: auto;
        }
    }
    &__container {
        @extend %reset;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 0.25 * $whitespace--inner-block;
        padding-bottom: 0.25 * $whitespace--inner-block;
        user-select: none;
        max-width: #{map-get($breakpoints, lap)};
        margin: 0 auto;
        @include respond-to('>=lap') {
            justify-content: space-between;
        }
    }
    &__list {
        @extend %reset;
        display: flex;
        justify-content: space-around;
        width: 100%;
        @include respond-to('>=lap') {
            width: auto;
        }
    }
    &__item {
        user-select: none;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0%;
        align-items: center;
        color: $color--grey03;
        text-decoration: none;
        padding: 0.5 * $whitespace--inner-block;
        font-size: 12px;
        @include respond-to('>=lap') {
            flex-direction: row;
            padding-left: $whitespace--inner-block;
            padding-right: $whitespace--inner-block;
            font-size: 1rem;
        }
        &.active {
            color: $color--cta-contrast;
            svg {
                fill: $color--cta-contrast;
            }
        }
        svg {
            fill: $color--grey03;
            width: 2rem;
            height: 2rem;
            padding-bottom: 0.25 * $whitespace--inner-block;
            @include respond-to('>=lap') {
                padding-bottom: 0;
                margin-right: 0.25 * $whitespace--inner-block;
            }
        }
    }
}