@import 'styles/reset';
@import 'styles/media-queries';
@import 'styles/colors';
@import 'styles/whitespace';
@import 'styles/typography';
@import 'styles/icons';
@import 'styles/vendor/fontawesome';
@import 'styles/placeholder';
@import 'styles/layout';

@import 'helpers/InProgress';
@import 'helpers/scrollable/useScrollable';
@import 'helpers/AboutView';

@import 'categories/CategoriesList';
@import 'comments/RepliesList';
@import 'comments/ReplyForm';
@import 'pages/DefaultView';
@import 'pages/ContactView';
@import 'pages/FeedView';
@import 'pages/ChartView';
@import 'posts/PostsList';
@import 'posts/PostView';
@import 'navigation/NavigationView';
@import 'navigation/SubNavigation';
@import 'events/SparseEventBlock';
@import 'events/EventView';
@import 'HomeView';
@import 'form/FormFactory';
@import 'title/Title';
@import 'pwa/Installbanner';

:root {
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  @extend %reset;
  @extend %typography__content;
  color: $color--content;
  background-color: $color--content-contrast;
}

.App {
  margin: 0 auto;
  @include respond-to('>=lap') {
    padding-top: 3 * $whitespace--inner-block;
    max-width: #{map-get($breakpoints, lap)};
  }
}