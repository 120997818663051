%form-view__input {
  @extend %reset--formelement;
  @extend %whitespace__inline-block;
  border: 1px solid $color--cta-contrast;
  box-sizing: border-box;
  width: 100%;
}
%form-view__field {
  & + * {
    margin-top: $whitespace--inner-block;
  }
}

.text-field {
  @extend %form-view__field;

  &__label {
    display: none;
  }
  &__input--medium {
    @extend %form-view__input;
  }
}

.text-area {
  @extend %form-view__field;

  &__label {
    display: none;
  }
  &__input--medium {
    @extend %form-view__input;
    height: 10em;
  }
}